import axios from 'axios';
import config from './config.js';
import router from '../router/index.js';
// import Vue from 'vue';
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: config.defaultUrl,
    timeout: 10000,
    headers: {
        "Authorization": localStorage.getItem("_token")
    }
});

service.interceptors.request.use(
    config => {
		config.headers.Authorization = localStorage.getItem("_token");
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if(response.data.code == 401){
                // 登录过期
                // Vue.prototype.$message.error(response.data.msg);
                router.push('/login')
            }else if(response.data.code == 200) {
                // 正常返回
            }else {
                // Vue.prototype.$message.error(response.data.msg);
            }
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        // Vue.prototype.$message.error("请求失败，请稍后再试！");
        console.log(error);
        return Promise.reject();
    }
);

export default service;