<template>
	<el-config-provider :locale="locale">
		<router-view />
	</el-config-provider>
</template>

<script>
	import * as echarts from 'echarts'
	import {
		ElConfigProvider
	} from 'element-plus'
	import zhCn from 'element-plus/lib/locale/lang/zh-cn'
	import {
		provide
	} from 'vue'
	export default {
		name: 'App',
		components: {
			[ElConfigProvider.name]: ElConfigProvider, //添加组件
		},
		data() {
			return {
				locale: zhCn
			}
		},
		setup() {
			provide('ec', echarts) //provide
		},
	};
</script>

<style>
	@import "./assets/css/main.css";
	@import "./assets/css/color-dark.css";
</style>
