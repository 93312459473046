<template>
	<div class="header">
		<!-- 折叠按钮 -->
		<div class="collapse-btn" @click="collapseChage">
			<i v-if="!collapse" class="el-icon-s-fold"></i>
			<i v-else class="el-icon-s-unfold"></i>
		</div>
		<div class="logo">后台管理系统</div>
		<div class="header-right">
			<div class="header-user-con">
				<!-- 消息中心 -->
				<!-- <div class="btn-bell">
					<el-tooltip effect="dark" :content="message?`有${message}条未读消息`:`消息中心`" placement="bottom">
						<router-link to="/tabs">
							<i class="el-icon-bell"></i>
						</router-link>
					</el-tooltip>
					<span class="btn-bell-badge" v-if="message"></span>
				</div> -->
				<el-popover placement="bottom" :width="200" trigger="hover" @show="getMsgList">
					<template #reference>
						<div class="msg">
							<i class="iconfont el-icon-bell"></i>
							<div class="msg-count" v-if="notify>0">{{notify}}</div>
						</div>
					</template>
					<div class="msg-list" v-loading="msgLoading">
						<router-link class="tips-item" :to="routerArr[index]" v-for="(item,index) in notifyLists"
							:key="index">
							<p class="tips-p">{{item.name}}</p>
							<p class="tips-p">{{item.num}}</p>
						</router-link>
					</div>
				</el-popover>
				<!-- 用户头像 -->
				<div class="user-avator">
					<img src="../assets/img/img.jpg" />
				</div>
				<!-- 用户名下拉菜单 -->
				<el-dropdown class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link">
						{{username}}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<!-- <a href="https://github.com/lin-xin/vue-manage-system" target="_blank">
                                <el-dropdown-item>项目仓库</el-dropdown-item>
                            </a> -->
							<el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
							<el-dropdown-item divided command="editPassword">修改密码</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
		<!-- 修改密码 -->
		<el-dialog
		title="修改密码"
		v-model="dialogVisible"
		:before-close="closeinput"
		width="50%"
		>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right" label-width="114px" class="demo-ruleForm">
			<el-form-item label="旧密码" prop="old_password">
				<el-input v-model="ruleForm.old_password" type="password" show-password autocomplete="off" placeholder="请输入旧密码"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="password">
				<el-input type="password" v-model="ruleForm.password" show-password autocomplete="off" placeholder="请输入新密码"></el-input>
			</el-form-item>
			<el-form-item label="新密码确认" prop="u_password">
				<el-input type="password" v-model="ruleForm.u_password"  show-password autocomplete="off" placeholder="请确认新密码"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="closeinput">取消</el-button>
				<el-button type="primary" @click="editPassword">确定</el-button>
			</span>
		</template>
		
		</el-dialog>
	</div>
</template>
<script>
	// import request from '../utils/request'
	import { getMsgList,getCount } from '@/utils/common.js'
	export default {
		data() {
			var oldvalidatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入旧密码'));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
				callback(new Error('请确认新密码'));
				} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'));
				} else {
				callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('密码不能为空'));
				} else {
					if (this.ruleForm.repassword !== '') {
						this.$refs.ruleForm.validateField('repassword');
					}
					callback();
				}
			};
			return {
				fullscreen: false,
				name: "linxin",
				message: 2,
				msgLoading: false,
				getMsgList,
				getCount,
				notifyTimer: '',
				// notify: '',
				// notifyLists: [],
				routerArr: ['/review', '/columnist', '/headImg', '/nickName', '/signature','/teacher','/tags','/answers','/sloganCheck'], //消息列表跳转对应地址
				dialogVisible:false,
				ruleForm: {
					old_password: '',
					password: '',
					u_password: ''
				},
				rules: {
					old_password: [
						{ validator:oldvalidatePass, trigger: 'blur' }
					],
					password: [
						{ validator:validatePass, trigger: 'blur' }
					],
					u_password: [
						{ validator: validatePass2, trigger: 'change' }
					]
				}
			};
		},
		computed: {
			username() {
				let username = localStorage.getItem("ms_username");
				return username ? username : this.name;
			},
			collapse() {
				return this.$store.state.collapse;
			},
			notify() {
				return this.$store.state.msgCount;
			},
			notifyLists() {
				return this.$store.state.msgList;
			}
		},
		created() {
			window.clearInterval(this.notifyTimer)
			this.notifyTimer = setInterval(() => {
				this.getCount()
			}, 12000)
			this.getMsgList()
			this.getCount()
		},
		methods: {
			closeinput(){
				this.$refs.ruleForm.resetFields();
				this.dialogVisible = false
			},
			editPassword(){
				this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.submitPas()
				} else {
					return false;
				}
				});
			},
			submitPas(){
				this.$axios({
					method: "post",
					url: "admin/index/modifyPwd",
					data:{
						...this.ruleForm
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success',
							duration:1000
						});
						this.dialogVisible = false
						this.$refs.ruleForm.resetFields()
					}else {
						this.$message({
							message: res.msg,
							type: 'error',
							duration:1000
						});
					}
				})
			},
			// 用户名下拉菜单选择事件
			handleCommand(command) {
				if (command == "loginout") {
					localStorage.removeItem("ms_username");
					this.$router.push("/login");
				}else {
					this.dialogVisible = true
				}
			},
			// 侧边栏折叠
			collapseChage() {
				this.$store.commit("hadndleCollapse", !this.collapse);
			},
			// 更新消息数和计数
			upDateMsg() {
				this.getMsgList()
				this.getCount()
			},
			// 获取消息列表
			// getMsgList() {
			// 	this.msgLoading = true
			// 	request({
			// 		url: 'admin/index/notifyList',
			// 		method: 'get',
			// 	}).then(({
			// 		code,
			// 		data
			// 	}) => {
			// 		if (code == 200) {
			// 			// console.log(data)
			// 			this.notifyLists = data
			// 		} else {
			// 			//this.$message.error(res.msg)
			// 		}
			// 		this.msgLoading = false
			// 	}).catch(err => {
			// 		console.log(err)
			// 	});
			// },
			// getCount() {
			// 	request({
			// 		url: 'admin/index/notifyCount',
			// 		method: 'get',
			// 	}).then(({
			// 		code,
			// 		data
			// 	}) => {
			// 		if (code == 200) {
			// 			// console.log(data)
			// 			this.notify = data
			// 		} else {
			// 			//this.$message.error(res.msg)
			// 		}
			// 	}).catch(err => {
			// 		console.log(err)
			// 	});
			// }
		},
		mounted() {
			if (document.body.clientWidth < 1500) {
				this.collapseChage();
			}
		}
	};
</script>
<style lang="scss" scoped>
	.tips-item {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ececec;
		color: #333;
	}

	.msg {
		display: flex;
		align-items: center;
		height: 100%;
		cursor: pointer;
		position: relative;

		.iconfont {
			font-size: 22px;
			margin-right: 4px;
			// color: #333333;
		}

		.msg-count {
			min-width: 16px;
			height: 16px;
			background: #FF5E5E;
			border-radius: 8px;
			position: absolute;
			box-sizing: border-box;
			padding: 0 5px;
			text-align: center;
			line-height: 16px;
			left: 14px;
			top: 20px;
			font-size: 12px;
		}

		.msg-text {
			font-size: 14px;
			font-weight: 500;
			// color: #333333;
			position: relative;


		}
	}

	.header {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 70px;
		font-size: 22px;
		color: #fff;
	}

	.collapse-btn {
		float: left;
		padding: 0 21px;
		cursor: pointer;
		line-height: 70px;
	}

	.header .logo {
		float: left;
		width: 250px;
		line-height: 70px;
	}

	.header-right {
		float: right;
		padding-right: 50px;
	}

	.header-user-con {
		display: flex;
		height: 70px;
		align-items: center;
	}

	.btn-fullscreen {
		transform: rotate(45deg);
		margin-right: 5px;
		font-size: 24px;
	}

	.btn-bell,
	.btn-fullscreen {
		position: relative;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 15px;
		cursor: pointer;
	}

	.btn-bell-badge {
		position: absolute;
		right: 0;
		top: -2px;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background: #f56c6c;
		color: #fff;
	}

	.btn-bell .el-icon-bell {
		color: #fff;
	}

	.user-name {
		margin-left: 10px;
	}

	.user-avator {
		margin-left: 20px;
	}

	.user-avator img {
		display: block;
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.el-dropdown-link {
		color: #fff;
		cursor: pointer;
	}

	.el-dropdown-menu__item {
		text-align: center;
	}
</style>
