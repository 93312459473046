import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
import request from './utils/request'
const app = createApp(App)
installElementPlus(app)
// import ElementPlus from 'element-plus'
// import 'element-plus/lib/theme-chalk/index.css'
// import zhCn from 'element-plus/es/locale/lang/zh-cn' // 中文
app
	.use(store)
	.use(router)
	// .use(ElementPlus)
	.mixin({
		methods: {
			$axios: request
		}
	})
	.mount('#app')
