import {
	createRouter,
	createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
	path: '/',
	redirect: '/home'
}, {
	path: "/",
	name: "Home",
	component: Home,
	children: [{
			path: "/home",
			name: "home",
			meta: {
				title: '系统首页'
			},
			component: () => import(
				/* webpackChunkName: "home" */
				"../views/index.vue")
		}, {
			path: "/table",
			name: "basetable",
			meta: {
				title: '表格'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/BaseTable.vue")
		}, {
			path: "/charts",
			name: "basecharts",
			meta: {
				title: '图表'
			},
			component: () => import(
				/* webpackChunkName: "charts" */
				"../views/BaseCharts.vue")
		}, {
			path: "/form",
			name: "baseform",
			meta: {
				title: '表单'
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/BaseForm.vue")
		}, {
			path: "/tabs",
			name: "tabs",
			meta: {
				title: 'tab标签'
			},
			component: () => import(
				/* webpackChunkName: "tabs" */
				"../views/Tabs.vue")
		}, {
			path: "/donate",
			name: "donate",
			meta: {
				title: '鼓励作者'
			},
			component: () => import(
				/* webpackChunkName: "donate" */
				"../views/Donate.vue")
		}, {
			path: "/permission",
			name: "permission",
			meta: {
				title: '权限管理',
				permission: true
			},
			component: () => import(
				/* webpackChunkName: "permission" */
				"../views/Permission.vue")
		}, {
			path: "/i18n",
			name: "i18n",
			meta: {
				title: '国际化语言'
			},
			component: () => import(
				/* webpackChunkName: "i18n" */
				"../views/I18n.vue")
		}, {
			path: "/upload",
			name: "upload",
			meta: {
				title: '上传插件'
			},
			component: () => import(
				/* webpackChunkName: "upload" */
				"../views/Upload.vue")
		}, {
			path: "/icon",
			name: "icon",
			meta: {
				title: '自定义图标'
			},
			component: () => import(
				/* webpackChunkName: "icon" */
				"../views/Icon.vue")
		},
		// 以下为新的路由
		// 系统管理开始
		{
			path: '/rightsManagement',
			name: 'rightsManagement',
			meta: {
				title: '权限管理'
			},
			component: () => import( /* webpackChunkName: "rightsManagement" */
				'../views/systemManagement/rightsManagement')
		},
		{
			path: '/userManagement',
			name: 'userManagement',
			meta: {
				title: '用户管理'
			},
			component: () => import( /* webpackChunkName: "userManagement" */
				'../views/systemManagement/userManagement')
		},
		{
			path: '/roleManagement',
			name: 'roleManagement',
			meta: {
				title: '角色管理'
			},
			component: () => import( /* webpackChunkName: "roleManagement" */
				'../views/systemManagement/roleManagement')
		},
		{
			path: '/pointConfig',
			name: 'pointConfig',
			meta: {
				title: '积分规则配置'
			},
			component: () => import( /* webpackChunkName: "pointConfig" */
				'../views/systemManagement/pointConfig')
		},
		// 系统管理结束
		// 分类开始
		{
			path: '/classify',
			name: 'classify',
			meta: {
				title: '分类管理'
			},
			component: () => import( /* webpackChunkName: "classify" */
				'../views/classify/index')
		},
		// 分类结束
		// 审核开始
		{
			path: '/review',
			name: 'review',
			meta: {
				title: '资源审核'
			},
			component: () => import( /* webpackChunkName: "review" */
				'../views/review/index')
		},
		{
			path: '/columnist',
			name: 'columnist',
			meta: {
				title: '专栏审核'
			},
			component: () => import( /* webpackChunkName: "columnist" */
				'../views/review/columnist')
		},
		{
			path: '/headImg',
			name: 'headImg',
			meta: {
				title: '头像审核'
			},
			component: () => import( /* webpackChunkName: "headImg" */
				'../views/review/headImg')
		},
		{
			path: '/nickName',
			name: 'nickName',
			meta: {
				title: '昵称审核'
			},
			component: () => import( /* webpackChunkName: "nickName" */
				'../views/review/nickName')
		},
		{
			path: '/signature',
			name: 'signature',
			meta: {
				title: '签名审核'
			},
			component: () => import( /* webpackChunkName: "signature" */
				'../views/review/signature')
		},
		{
			path: '/teacher',
			name: 'teacher',
			meta: {
				title: '老师审核'
			},
			component: () => import( /* webpackChunkName: "teacher" */
				'../views/review/teacher')
		},
		{
			path: '/tags',
			name: 'tags',
			meta: {
				title: '标签审核'
			},
			component: () => import( /* webpackChunkName: "tags" */
				'../views/review/tags')
		},
		{
			path: '/answers',
			name: 'answers',
			meta: {
				title: '问答审核'
			},
			component: () => import( /* webpackChunkName: "answers" */
				'../views/review/answers')
		},
		// 审核结束

		// 举报管理开始
		{
			path: '/complain',
			name: 'complain',
			meta: {
				title: '内容举报'
			},
			component: () => import( /* webpackChunkName: "complain" */
				'../views/complain/index')
		},
		{
			path: '/answerComplain',
			name: 'answerComplain',
			meta: {
				title: '问答举报'
			},
			component: () => import( /* webpackChunkName: "answerComplain" */
				'../views/complain/answerComplain')
		},
		{
			path: '/answerIndex',
			name: 'answerIndex',
			meta: {
				title: '回答举报'
			},
			component: () => import( /* webpackChunkName: "answerIndex" */
				'../views/complain/answerIndex')
		},
		{
			path: '/questionIndex',
			name: 'questionIndex',
			meta: {
				title: '问题举报'
			},
			component: () => import( /* webpackChunkName: "questionIndex" */
				'../views/complain/questionIndex')
		},
		// 举报管理结束
		
		// 直播举报管理开始
		{
			path: '/complainLive',
			name: 'complainLive',
			meta: {
				title: '直播举报管理'
			},
			component: () => import( /* webpackChunkName: "complainLive" */
				'../views/complain/live')
		},
		{
			path: '/complainRecord',
			name: 'complainRecord',
			meta: {
				title: '回放举报管理'
			},
			component: () => import( /* webpackChunkName: "complainRecord" */
				'../views/complain/record')
		},

		// 申诉管理开始
		{
			path: '/appeal',
			name: 'appeal',
			meta: {
				title: '申诉管理'
			},
			component: () => import( /* webpackChunkName: "appeal" */
				'../views/appeal/index')
		},
		// 申诉管理结束

		// 基础数据开始

		// 标签管理开始
		{
			path: '/tag',
			name: 'tag',
			meta: {
				title: '标签管理'
			},
			component: () => import( /* webpackChunkName: "tag" */
				'../views/basis/tag')
		},
		{
			path: '/questionTag',
			name: 'questionTag',
			meta: {
				title: '标签管理'
			},
			component: () => import( /* webpackChunkName: "questionTag" */
				'../views/basis/questionTag')
		},
		// 标签管理结束
		// 充值管理开始
		{
			path: '/beans',
			name: 'beans',
			meta: {
				title: '充值配置'
			},
			component: () => import( /* webpackChunkName: "beans" */
				'../views/basis/beans')
		},
		// 充值管理结束
		// 礼物管理开始
		{
			path: '/gift',
			name: 'gift',
			meta: {
				title: '礼物管理'
			},
			component: () => import( /* webpackChunkName: "gift" */
				'../views/basis/gift')
		},
		// 礼物管理结束
		// 开户行管理开始
		{
			path: '/bank',
			name: 'bank',
			meta: {
				title: '开户行管理'
			},
			component: () => import( /* webpackChunkName: "bank" */
				'../views/basis/bank')
		},
		// 开户行管理结束
		// 敏感词
		{
			path: '/sensitive',
			name: 'sensitive',
			meta: {
				title: '敏感词管理'
			},
			component: () => import( /* webpackChunkName: "sensitive" */
				'../views/basis/sensitive')
		},
		// 审核不通过原因
		{
			path: '/reason',
			name: 'reason',
			meta: {
				title: '审核原因列表'
			},
			component: () => import( /* webpackChunkName: "reason" */
				'../views/basis/reason')
		},
		// 基础数据结束

		// 统计分析开始
		{
			path: '/author',
			name: 'statisticsAuthor',
			meta: {
				title: '创作者查询'
			},
			component: () => import( /* webpackChunkName: "author" */
				'../views/statistics/author')
		},
		{
			path: '/user',
			name: 'statisticsUser',
			meta: {
				title: '用户查询'
			},
			component: () => import( /* webpackChunkName: "user" */
				'../views/statistics/user')
		},
		{
			path: '/anchor',
			name: 'statisticsAnchor',
			meta: {
				title: '老师查询'
			},
			component: () => import( /* webpackChunkName: "anchor" */
				'../views/statistics/anchor')
		},
		// 统计分析结束
		// 广告管理模块
		{
			path: '/sponsorCheck',
			name: 'sponsorCheck',
			meta: {
				title: '赞助商审核'
			},
			component: () => import( /* webpackChunkName: "sponsorCheck" */
				'../views/advertising/sponsorCheck')
		},
		{
			path: '/briefIntroduction',
			name: 'briefIntroduction',
			meta: {
				title: '赞助商公司简称审核'
			},
			component: () => import( /* webpackChunkName: "briefIntroduction" */
				'../views/advertising/briefIntroduction')
		},
		{
			path: '/sponsorLogo',
			name: 'sponsorLogo',
			meta: {
				title: '赞助商公司LOGO审核'
			},
			component: () => import( /* webpackChunkName: "sponsorLogo" */
				'../views/advertising/sponsorLogo')
		},
		{
			path: '/sloganCheck',
			name: 'sloganCheck',
			meta: {
				title: '广告审核'
			},
			component: () => import( /* webpackChunkName: "sloganCheck" */
				'../views/advertising/sloganCheck')
		},
		// 广告管理模块结束
		{
			path: '/404',
			name: '404',
			meta: {
				title: '找不到页面'
			},
			component: () => import( /* webpackChunkName: "404" */
				'../views/404.vue')
		}, {
			path: '/403',
			name: '403',
			meta: {
				title: '没有权限'
			},
			component: () => import( /* webpackChunkName: "403" */
				'../views/403.vue')
		}
	]
}, {
	path: "/login",
	name: "Login",
	meta: {
		title: '登录'
	},
	component: () => import(
		/* webpackChunkName: "login" */
		"../views/Login.vue")
}];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	// document.title = `${to.meta.title} | 龟鹤学堂`;
	const role = localStorage.getItem('ms_username');
	if (!role && to.path !== '/login') {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
		role === 'admin' ?
			next() :
			next('/403');
	} else {
		next();
	}
});

export default router;
