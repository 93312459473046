import request from '@/utils/request'
import store from '@/store/index.js'
import config from './config.js';
const getMsgList = () => {
	request({
		url: 'admin/index/notifyList',
		method: 'get',
	}).then(({
		code,
		data
	}) => {
		console.log(data,1111111)
		if (code == 200) {
			
			store.commit('setMsgList', data)
		} else {
			//this.$message.error(res.msg)
		}
	}).catch(err => {
		console.log(err)
	});
}

const getCount = () => {
	request({
		url: 'admin/index/notifyCount',
		method: 'get',
	}).then(({
		code,
		data
	}) => {
		if (code == 200) {
			// console.log(data)
			store.commit('setMsgCount', data)
		} else {
			//this.$message.error(res.msg)
		}
	}).catch(err => {
		console.log(err)
	});
}
export const filterCosImage = (url)=> {
	if(!url) return ''
    const defaulturl = config.defaultUrl
    let imgUrl = ''
	if(url.startsWith('https://')) {
		imgUrl = url
	}  else {
		imgUrl = defaulturl+url
	}
    return imgUrl
}
export {
	getMsgList,
	getCount
}