<template>
    <div class="about">
        <v-header />
        <v-sidebar />
        <div class="content-box" :class="{ 'content-collapse': collapse }">
            <!-- <v-tags></v-tags> -->
            <div class="content">
                <router-view v-slot="{ Component }">
                    <transition name="move" mode="out-in">
                        <!-- <keep-alive :include="tagsList"> -->
                            <component :is="Component" />
                        <!-- </keep-alive> -->
                    </transition>
                </router-view>
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "../components/Header";
import vSidebar from "../components/Sidebar";
import vTags from "../components/Tags.vue";
export default {
    components: {
        vHeader,
        vSidebar,
        vTags
    },
    computed: {
        tagsList() {
            return this.$store.state.tagsList.map(item => item.name);
        },
        collapse() {
            return this.$store.state.collapse;
        }
    },
    mounted(){
        this.getCosInfo()
    },
    methods:{
        // 获取腾讯云存储信息
        getCosInfo() {
            this.$axios({
                url: 'admin/getCosConfig',
                method: 'get',
            }).then(({
                code,
                data,
                msg
            }) => {
                if (code == 200) {
                    console.log(data)
                    this.$store.commit('setCosInfo',{Bucket:data.bucket,Region:data.region})
                } else {
                    this.$message.error(msg)
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }
};
</script>
